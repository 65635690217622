import { metadataActions } from "../../actions";
import initialState from "../../initialState";

function metadata(state = initialState.metadata, actions) {
  switch (actions.type) {
    case metadataActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metadataError: {},
        loading: true,
      };
    case metadataActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metadata: actions.data,
        loading: false,
      };
    case metadataActions.GET_META_DATA_ERROR:
      return {
        ...state,
        metadataError: actions.error,
        loading: false,
      };
    default:
      return state;
  }
}

export default metadata;
