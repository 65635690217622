import axios from "axios";
import { isArray } from "lodash";
import { fork, put, takeLatest } from "redux-saga/effects";
import {
  applicationSectionActions,
  runningAdmissionActions,
} from "../../actions";

function* getApplicationSection(actions) {
  try {
    const response = yield axios({
      url: `/applicants/${actions.sectionUrl}`,
      method: "GET",
    });
    yield put({
      type: applicationSectionActions.GET_APPLICATION_SECTION_SUCCESS,
      data: response.data,
      sectionUrl: actions.sectionUrl,
    });
  } catch (error) {
    yield put({
      type: applicationSectionActions.GET_APPLICATION_SECTION_ERROR,
      error: error.data,
    });
  }
}

function* createApplicationSection(actions) {
  try {
    const response = yield axios({
      url: `/applicants/${actions.sectionUrl}`,
      method: "POST",
      data: actions.payload,
      headers: actions.config,
    });
    yield put({
      type: applicationSectionActions.CREATE_APPLICATION_SECTION_SUCCESS,
      data: response,
    });
    yield put({
      type: applicationSectionActions.GET_APPLICATION_SECTION_REQUEST,
      sectionUrl: `${actions.sectionUrl}/${
        isArray(response.data)
          ? response.data[0].form_id
          : response.data.form_id
      }`,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST,
      runningAdmissionId: isArray(response.data)
        ? response.data[0].running_admission_id
        : response.data.running_admission_id,
    });
  } catch (error) {
    yield put({
      type: applicationSectionActions.CREATE_APPLICATION_SECTION_ERROR,
      error: error.data,
    });
  }
}

function* updateApplicationSection(actions) {
  try {
    const response = yield axios({
      url: `/applicants/${actions.sectionUrl}`,
      method: "PUT",
      data: actions.payload,
      headers: actions.config,
    });
    yield put({
      type: applicationSectionActions.UPDATE_APPLICATION_SECTION_SUCCESS,
      data: response,
    });
    yield put({
      type: applicationSectionActions.GET_APPLICATION_SECTION_REQUEST,
      sectionUrl: `${actions.sectionUrl.split("/")[0]}/${
        actions.payload.form_id || response.data?.form_id
      }`,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST,
      runningAdmissionId:
        actions.payload.running_admission_id ||
        response.data?.running_admission_id,
    });
  } catch (error) {
    yield put({
      type: applicationSectionActions.UPDATE_APPLICATION_SECTION_ERROR,
      error: error.data,
    });
  }
}

function* deleteApplicationSection(actions) {
  try {
    const response = yield axios({
      url: `/applicants/${actions.sectionUrl}/${actions.sectionId}`,
      method: "DELETE",
    });
    yield put({
      type: applicationSectionActions.DELETE_APPLICATION_SECTION_SUCCESS,
      data: response,
    });
    yield put({
      type: applicationSectionActions.GET_APPLICATION_SECTION_REQUEST,
      sectionUrl: `${actions.sectionUrl}/${actions.data.form_id}`,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST,
      runningAdmissionId: actions.data.running_admission_id,
    });
  } catch (error) {
    yield put({
      type: applicationSectionActions.DELETE_APPLICATION_SECTION_ERROR,
      error: error.data,
    });
  }
}

function* getApplicantUnebResult(actions) {
  try {
    const response = yield axios({
      url: `/applicants/uneb-results`,
      method: "POST",
      data: actions?.data,
    });
    yield put({
      type: applicationSectionActions.GET_UNEB_RESULT_SUCCESS,
      data: { ...response?.data, ...actions?.data },
    });
  } catch (error) {
    yield put({
      type: applicationSectionActions.GET_UNEB_RESULT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetApplicationSection() {
  yield takeLatest(
    applicationSectionActions.GET_APPLICATION_SECTION_REQUEST,
    getApplicationSection
  );
}

function* watchCreateApplicationSection() {
  yield takeLatest(
    applicationSectionActions.CREATE_APPLICATION_SECTION_REQUEST,
    createApplicationSection
  );
}

function* watchUpdateApplicationSection() {
  yield takeLatest(
    applicationSectionActions.UPDATE_APPLICATION_SECTION_REQUEST,
    updateApplicationSection
  );
}

function* watchDeleteApplicationSection() {
  yield takeLatest(
    applicationSectionActions.DELETE_APPLICATION_SECTION_REQUEST,
    deleteApplicationSection
  );
}

function* watchGetApplicationResult() {
  yield takeLatest(
    applicationSectionActions.GET_UNEB_RESULT_REQUEST,
    getApplicantUnebResult
  );
}

const forkFunctions = [
  fork(watchGetApplicationSection),
  fork(watchCreateApplicationSection),
  fork(watchUpdateApplicationSection),
  fork(watchDeleteApplicationSection),
  fork(watchGetApplicationResult),
];

export default forkFunctions;
