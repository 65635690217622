const runningAdmission = {
  GET_RUNNING_ADMISSION_REQUEST: "GET_RUNNING_ADMISSION_REQUEST",
  GET_RUNNING_ADMISSION_SUCCESS: "GET_RUNNING_ADMISSION_SUCCESS",
  GET_RUNNING_ADMISSION_ERROR: "GET_RUNNING_ADMISSION_ERROR",

  GET_SELECTED_RUNNING_ADMISSION_SUCCESS:
    "GET_SELECTED_RUNNING_ADMISSION_SUCCESS",
  GET_SELECTED_RUNNING_ADMISSION_REQUEST:
    "GET_SELECTED_RUNNING_ADMISSION_REQUEST",
  GET_SELECTED_RUNNING_ADMISSION_ERROR: "GET_SELECTED_RUNNING_ADMISSION_ERROR",

  GET_RUNNING_ADMISSION_PROGRAMMES_SUCCESS:
    "GET_RUNNING_ADMISSION_PROGRAMMES_SUCCESS",
  GET_RUNNING_ADMISSION_PROGRAMMES_REQUEST:
    "GET_RUNNING_ADMISSION_PROGRAMMES_REQUEST",
  GET_RUNNING_ADMISSION_PROGRAMMES_ERROR:
    "GET_RUNNING_ADMISSION_PROGRAMMES_ERROR",

  GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_SUCCESS:
    "GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_SUCCESS",
  GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST:
    "GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST",
  GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_ERROR:
    "GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_ERROR",

  SET_SELECTED_RUNNING_ADMISSION: "SET_SELECTED_RUNNING_ADMISSION",
  SET_SELECTED_RUNNING_ADMISSION_PROGRAMMES:
    "SET_SELECTED_RUNNING_ADMISSION_PROGRAMMES",
  SET_CURRENT_RUNNING_ADMISSION_CONTEXT:
    "SET_CURRENT_RUNNING_ADMISSION_CONTEXT",

  SUBMIT_APPLICANT_FORM_SUCCESS: "SUBMIT_APPLICANT_FORM_SUCCESS",
  SUBMIT_APPLICANT_FORM_REQUEST: "SUBMIT_APPLICANT_FORM_REQUEST",
  SUBMIT_APPLICANT_FORM_ERROR: "SUBMIT_APPLICANT_FORM_ERROR",

  SET_MY_APPLICATION_FORMS: "SET_MY_APPLICATION_FORMS",

  getRunningAdmissions: (data) => ({
    type: runningAdmission.GET_RUNNING_ADMISSION_REQUEST,
    data,
  }),

  getSingleRunningAdmission: (runningAdmissionId) => ({
    type: runningAdmission.GET_SELECTED_RUNNING_ADMISSION_REQUEST,
    runningAdmissionId,
  }),

  getRunningAdmissionProgrammes: (runningAdmissionId) => ({
    type: runningAdmission.GET_RUNNING_ADMISSION_PROGRAMMES_REQUEST,
    runningAdmissionId,
  }),

  getRunningAdmissionApplicantContext: (runningAdmissionId) => ({
    type: runningAdmission.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST,
    runningAdmissionId,
  }),

  setSelectedRunningAdmission: (payload) => ({
    type: runningAdmission.SET_SELECTED_RUNNING_ADMISSION,
    payload,
  }),

  setSelectedRunningAdmissionProgrammes: (payload) => ({
    type: runningAdmission.SET_SELECTED_RUNNING_ADMISSION_PROGRAMMES,
    payload,
  }),

  setMyApplicationForms: (payload) => ({
    type: runningAdmission.SET_MY_APPLICATION_FORMS,
    payload,
  }),

  setCurrentRunningAdmissionContext: (payload) => ({
    type: runningAdmission.SET_CURRENT_RUNNING_ADMISSION_CONTEXT,
    payload,
  }),

  submitApplicationForm: (formID, payload) => ({
    type: runningAdmission.SUBMIT_APPLICANT_FORM_REQUEST,
    formID,
    payload,
  }),
};

export default runningAdmission;
