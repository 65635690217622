import { takeLatest, fork, put } from "redux-saga/effects";
import axios from "axios";
import { metadataActions } from "../../actions";

function* getAllMetadata(actions) {
  try {
    const response = yield axios({
      url: "/applicants/meta-data",
      method: "GET",
      data: actions.data,
    });
    yield put({
      type: metadataActions.GET_META_DATA_SUCCESS,
      data: response.metadata,
    });
  } catch (error) {
    yield put({
      type: metadataActions.GET_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMetadata() {
  yield takeLatest(metadataActions.GET_META_DATA_REQUEST, getAllMetadata);
}

const forkFunctions = [fork(watchGetMetadata)];

export default forkFunctions;
