const setting = {
  setting: {
    selectedMenu: "apply-now",
    sideMenuTab: {},
    admissionFormSections: [],
    runningAdmissionTab: "all-running-admissions",
    showSelectProgrammeModal: false,
  },
};

export default setting;
