import React from "react";
import PropTypes, { any } from "prop-types";
import { Alert } from "react-bootstrap";

const AlertMessage = ({ message, type, className, icon, ...props }) => {
  return (
    <>
      <Alert variant={type} className={className} {...props}>
        {icon}
        {message}
      </Alert>
    </>
  );
};

AlertMessage.defaultProps = {
  type: "danger",
  message: null,
  icon: null,
  className: "py-1 text-sm font500",
};

AlertMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([any]),
};

export default AlertMessage;
