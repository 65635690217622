const metadata = {
  GET_META_DATA_REQUEST: "GET_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_META_DATA_SUCCESS",
  GET_META_DATA_ERROR: "GET_META_DATA_ERROR",

  getMetadata: (data) => ({
    type: metadata.GET_META_DATA_REQUEST,
    data,
  }),
};

export default metadata;
