const unebSubject = {
  GET_UNEB_SUBJECT_REQUEST: "GET_UNEB_SUBJECT_REQUEST",
  GET_UNEB_SUBJECT_SUCCESS: "GET_UNEB_SUBJECT_SUCCESS",
  GET_UNEB_SUBJECT_ERROR: "GET_UNEB_SUBJECT_ERROR",

  getUnebSubjects: (data) => ({
    type: unebSubject.GET_UNEB_SUBJECT_REQUEST,
    data,
  }),
};

export default unebSubject;
