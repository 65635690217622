import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import RoutePaths from "./config/routes/routePaths";
import PageLoader from "./containers/Dashboard/PageLoader";
import PageNotFound from "./containers/Errors/PageNotFound";
import "./custom.scss";

const Dashboard = React.lazy(() => import("./containers/Dashboard"));

function App() {
  useEffect(() => {
    // Prevent Going back to previous page
    window.history.pushState(null, document.title, "/");
    window.addEventListener("popstate", () => {
      // TO view the events, pass event as a prop to the function
      window.history.pushState(null, document.title, "/");
    });
  });

  return (
    <Suspense fallback={<PageLoader />}>
      <Router>
        <Switch>
          <Route exact path={RoutePaths.dashboard.path} component={Dashboard} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
